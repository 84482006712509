<template>
  <div class="flex flex-row w-full flex-nowrap">
    <p
      class="!mr-auto !ml-0 shrink-0 font-bold font-deco-alt"
      :class="headlineSizeClass"
    >
      <slot v-if="$slots.default" />
      <template v-else>{{ t(headline) }}</template>
    </p>
    <div
      class="w-full border-dashed border-b-1 mb-2xs ml-2xs"
      :class="borderColorClass"
    ></div>
  </div>
</template>
<script setup lang="ts">
defineProps({
  headline: {
    type: String,
    required: false,
    default: null,
  },
  headlineSizeClass: {
    type: String,
    required: false,
    default: 'text-alt-2xl',
  },
  borderColorClass: {
    type: String,
    required: false,
    default: 'border-border-light',
  },
});
const { t } = useTrans();
</script>
